import React, { useEffect, useState } from "react";
import { BsCurrencyDollar } from "react-icons/bs";
import Loading from "../component/Loading";
import UploadComponent from "../component/Upload";
import obtenerMesActual from "../data/utilidades"
import "../assets/css/Profile.css";
import Bancolombia from "../assets/img/bancolombia.png";
import PSE from "../assets/img/pse-logo.png";
import TarjetaDeCredito from "../assets/img/Tarjetas1.png";
import {Avatar} from 'keep-react';
const Profile = () => {
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ownProduct, setOwnProduct] = useState(false);
  useEffect(() => {
    const nit = localStorage.getItem("Empresa");
    // fetch(`${REACT_APP_API_URL}/getconfigempresa/${nit}`, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //       })
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setData(res);
    //     if (res) {
    //       setIsLoading(false);
    //     }
    //   });
    setData(JSON.parse(localStorage.getItem("config")));
    setIsLoading(false);
  }, []);
  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {/* Encabezado */}
          <h1 className="text-xl font-bold mb-4">Tu comercio</h1>

          {/* Información principal del comercio */}
          <div className="bg-white p-6 rounded-lg shadow-md mb-5 flex items-center">
            {/* Logo y nombre del comercio */}
            <img
              src={data.imagen ? data.imagen : ""}
              alt="Logo del comercio"
              className="mr-4 rounded-full logo-comercio"
            />
            <div>
              <h2 className="text-xl font-semibold">
                {data.nombre ? data.nombre : ""}
              </h2>
              <p className="text-gray-600">
                {data.nombre ? data.nombre : ""} (
                {data.correo ? data.correo : "Sin correo"})
              </p>
            </div>
          </div>

          {/* Descripción general de saldos */}
          {!ownProduct ? (
            ""
          ) : (
            <>
              <h2 className="text-lg font-bold mb-4">
                Descripción general de saldos ({obtenerMesActual()})
              </h2>
              <div className="grid grid-cols-2 gap-4 mb-5">
                {/* Aquí puedes reutilizar tu componente BalanceCard para cada tarjeta */}
                {/* Ejemplo de una de las tarjetas: */}
                <div className="bg-white p-5 rounded-lg shadow-md flex items-center space-x-4">
                  <span className="text-3xl text-gray-400">
                    <BsCurrencyDollar />
                  </span>
                  <div>
                    <h3 className="text-xl font-medium">$0,00</h3>
                    <p className="text-gray-600">1 Acreditado</p>
                  </div>
                </div>
                <div className="bg-white p-5 rounded-lg shadow-md flex items-center space-x-4">
                  <span className="text-3xl text-gray-400">
                    <BsCurrencyDollar />
                  </span>
                  <div>
                    <h3 className="text-xl font-medium">$0,00</h3>
                    <p className="text-gray-600">2 En tramite</p>
                  </div>
                </div>
                {/* Repite esto para las otras dos tarjetas */}
              </div>
            </>
          )}
 
          {/* Configuración de la cuenta */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-lg font-bold mb-4">
              Configuración de la cuenta
            </h2>
            <div className="divide-y divide-gray-200">
              <div className="py-2 flex justify-between">
                <span className="font-medium text-gray-600">Razón Social:</span>
                <span className="font-semibold">{data.nombre ? data.nombre : ""}</span>
              </div>
              <div className="py-2 flex justify-between">
                <span className="font-medium text-gray-600">
                  Identificación:
                </span>
                <span className="font-semibold">NIT {data.idcliente ? data.idcliente : ""}</span>
              </div>
              <div className="py-2 flex justify-between">
                <span className="font-medium text-gray-600">
                  Nombre contacto:
                </span>
                <span className="font-semibold">{data.correo ? data.correo : ""}</span>
              </div>
              <div className="py-2 flex justify-between">
                <span className="font-medium text-gray-600">Telefono:</span>
                <span className="font-semibold">{data.Telefono ? data.Telefono : ""}</span>
              </div>
              {/* Agrega más divs aquí para las otras piezas de información */}
            </div>
          </div>
          {/* Upload de la imagen */}
        <div className="bg-white p-6 rounded-lg shadow-md mt-2">
          <h2 className="text-lg font-bold mb-4">Imagen del comercio</h2>
            <UploadComponent  />
        </div> 

          {/* Metodos de pagos autorizados */}
         <div className="bg-white p-6 rounded-lg shadow-md mt-2">
          <h2 className="text-lg font-bold mb-4">
            Medios de pago autorizados
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {/* Bancolombia - Avatar Circular */}
            <div className="flex flex-col items-center">
              <div className="bg-gray-200 p-4 rounded-full w-16 h-16 overflow-hidden flex justify-center items-center">
                <img
                  src={Bancolombia}
                  alt="Bancolombia"
                  className="object-cover w-8 h-8 rounded-full"
                />
              </div>
              <span className="font-semibold mt-2 text-sm md:text-base">
                Bancolombia
              </span>
            </div>

            {/* PSE - Avatar Circular */}
            <div className="flex flex-col items-center">
              <div className="bg-gray-200 p-4 rounded-full w-16 h-16 overflow-hidden flex justify-center items-center">
                <img
                  src={PSE}
                  alt="PSE"
                  className="object-cover w-8 h-8 rounded-full"
                />
              </div>
              <span className="font-semibold mt-2 text-sm md:text-base">
                PSE
              </span>
            </div>

            {/* Tarjeta de crédito - Avatar Circular */}
            <div className="flex flex-col items-center">
              <div className="bg-gray-200 p-4 rounded-full w-16 h-16 overflow-hidden flex justify-center items-center">
                <img
                  src={TarjetaDeCredito}
                  alt="TarjetaDeCredito"
                  className="object-contain w-10 h-10"
                />
              </div>
              <span className="font-semibold mt-2 text-sm md:text-base">
                Tarjeta Crédito
              </span>
            </div>
          </div>
        </div>

        </>
      )}
    </div>
  );
};

export default Profile;
