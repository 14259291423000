'use client'
import { Info, Trash } from 'phosphor-react';
import { useCallback, useState } from 'react';
import { Upload, UploadIcon, UploadText } from 'keep-react';
import folderIcon from '../assets/img/folder.svg';
import Loading from "../component/Loading";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const UploadComponent = () => {
  // obtner valor de un local storage json, que tiene el nombe config, idcliente
  
  const [files, setFiles] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const validateFileType = (file) => {
    // validar si no tiene extesion de imagen
    if (!file.type) {
      setError('El archivo no tiene una extensión válida.');
      return false;
    }
    
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/jpg'];
    if (!allowedTypes.includes(file.type)) {
      setError('Solo se permiten archivos de tipo imagen: Formatos JPG, PNG, GIF, SVG, JPEG');
      return false;
    }
    setError('');
    return true;
  };

  const onDrop = useCallback((acceptedFiles) => {
    const validFiles = acceptedFiles.filter(validateFileType);
    setFiles(validFiles);
  }, []);

  const removeFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };
  
// Función para subir la imagen a la API
const handleUpload = async () => {
  // console.log(localStorage.getItem('config'));
  let config = JSON.parse(localStorage.getItem('config'));
  const idcliente = JSON.parse(localStorage.getItem('config')).idcliente;
  const NombreImagen = JSON.parse(localStorage.getItem('config')).nombre;
  // console.log(idcliente);
  // loading(true); // Mostrar un spinner de carga

  setLoading(true);
  if (files.length === 0) {
    alert("No hay archivos para subir.");
    return;
  }

  const formData = new FormData();
  formData.append("file", files[0]); // Adjuntar el primer archivo seleccionado

  try {
    const response = await fetch(`${REACT_APP_API_URL}/uploadImagen?idcliente=${idcliente}&NombreImagen=${NombreImagen}`, { // Cambia la URL a la de tu API
      method: "POST",
      body: formData,
    });

    const result = await response.json();
    if (response.ok) {
      // alert("Imagen enviada correctamente.");
      console.log("URL de la imagen:", result);
      config.imagen = result;
      localStorage.setItem("config", JSON.stringify(config));
      // Actualizar la pagina
       window.location.reload();
    } else {
      // alert("Error al enviar la imagen.");
      console.error("Error en la subida:", result);
    }
  } catch (error) {
    console.error("Error de red:", error);
    
    // alert("Hubo un problema con la subida.");
  }
  setLoading(false);
};

  return (
    <>
    {loading ? (
      <Loading />
    ) : (
    <Upload options={{ onDrop }} accept="image/*">
      <Upload.Body>
        <Upload.Icon>
          <img src={folderIcon} alt="folder" height={28} width={28} />
        </Upload.Icon>
        <Upload.Text>
          <p className="text-body-3 font-medium text-metal-600 dark:text-white">
            Arrastra y suelta o elige una imagen para subir
          </p>
          <p className="text-body-4 font-normal text-metal-400 dark:text-metal-300">
            Formatos JPG, PNG y GIF, hasta 25 MB.
          </p>
          {error && <p className="text-red-500">{error}</p>}
        </Upload.Text>
      </Upload.Body>
      <Upload.Footer isFileExists={files.length > 0}>
        <p className="my-2 flex items-center gap-1 text-body-4 font-normal text-metal-600 dark:text-metal-300">
          <Info size={16} />
          Archivos Subidos
        </p>
        <ul className="space-y-1">
          {files?.map((file) => (
            <li
              key={file?.name}
              className="flex items-center justify-between border-l-4 border-l-metal-100 bg-metal-25 px-4 py-2.5 text-left text-body-4 font-normal capitalize text-metal-600 dark:border-l-metal-600 dark:bg-metal-800 dark:text-metal-300 ">
              {file?.name}
              <Trash size={16} color="red" onClick={() => removeFile(file.name)} />
            </li>
          ))}
        </ul>
   {files.length > 0 && (
      <button
        type="button"
        onClick={handleUpload} // Evento para enviar la imagen
        className="mt-4 flex items-center gap-2 bg-blue-600 hover:bg-blue-700 transition text-white font-bold py-2 px-4 rounded-lg shadow-lg focus:outline-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 16v1a2 2 0 002 2h12a2 2 0 002-2v-1m-4-4l-4-4m0 0l-4 4m4-4v12"
          />
        </svg>
        <span>Subir Imagen</span>
      </button>
    )}

      </Upload.Footer>
    </Upload>
    )}
    </>
  )}

export default UploadComponent;
