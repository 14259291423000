import React, { useEffect, useState } from "react";
import { Avatar, Button, Table } from "keep-react";
import { DownloadSimple } from "phosphor-react";
import Spinner from "../component/Spinner";
import excelIcon from "../assets/img/excel.svg";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import "../assets/css/Desembolso.css";
import {
  formatCurrencydes,
  formatDateTime,
  toLocalISOString,
} from "../data/utilidades";
import DatePickerDes from "../component/DatePickerDes";
import * as XLSX from 'xlsx';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const Desembolsar = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingfile, setIsLoadingfile] = useState(false);
  const pageSize = 6;
  const empresa = localStorage.getItem("Empresa");
  // Data falsa inicial
  const [data, setData] = useState([]);
  const [Filter, setFilter] = useState(false);
  const init = () => {
    setIsLoadingfile(true);
    fetch(`${REACT_APP_API_URL}/desembolso/getDesembolsos?idcliente=${empresa}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setIsLoadingfile(false);
        //  console.log("data 2", data);
      })
      .catch((error) => {
        // setError(error.message);
        console.log(error);
      });
  };

  const SearchByDateRange = (FechaIni, FechaFinal) => {
    setIsLoadingfile(true);
    fetch(
      `${REACT_APP_API_URL}/desembolso/getDesembolsosDate?idcliente=${empresa}&FechaIni=${FechaIni}&FechaFinal=${FechaFinal}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(Array.isArray(data) ? data : []); // Asegúrate de que data sea un arreglo
        setIsLoadingfile(false);

      })
      .catch((error) => {
        // setError(error.message);
        console.log(error);
        setIsLoadingfile(false);
      });
  };
  useEffect(() => {
    init();
  }, []);

  // const totalPages = Math.ceil(data.length / pageSize);

  // const currentData = data.slice(
  //   (currentPage - 1) * pageSize,
  //   (currentPage - 1) * pageSize + pageSize
  // );
  const totalPages = Array.isArray(data)
    ? Math.ceil(data.length / pageSize)
    : 0;

  const currentData = Array.isArray(data)
    ? data.slice(
      (currentPage - 1) * pageSize,
      (currentPage - 1) * pageSize + pageSize
    )
    : [];

  const nextPage = () => {
    setCurrentPage((current) => (current < totalPages ? current + 1 : current));
  };

  const prevPage = () => {
    setCurrentPage((current) => (current > 1 ? current - 1 : current));
  };
  //funciones que necesita el datepicker
  const filterTblTransaccion = (startDate, endDate) => {
    // console.log("startDate", startDate, "endDate", endDate);
    SearchByDateRange(startDate, endDate);
  };
  const getdesinfo = (idc, namedes) => (e) => {
    e.preventDefault();
    setIsLoadingfile(true);
    fetch(
      `${REACT_APP_API_URL}/desembolso/getdesinfo?idc=${idc}&namedes=${namedes}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //  console.log(data);
        if (data !== null) {
          const fileName = namedes;
          const workbook = XLSX.utils.book_new();

          const worksheet = XLSX.utils.json_to_sheet(data);

          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

          XLSX.writeFile(workbook, `${fileName}.xlsx`);
        }
        setIsLoadingfile(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateData = () => {
    init();
  }
  return (
    <div className="overflow-x-auto" style={{ padding: "10px" }}>
      {/* sipinner en el centro */}
      <div className="spinn">
        {isLoadingfile && <Spinner color="gray" size="lg" />}
      </div>
      <Table className="table" showCheckbox={false}>
        <Table.Caption>
          <DatePickerDes update={updateData} filterTblTransaccion={filterTblTransaccion} />
        </Table.Caption>
        <Table.Head className="thead">
          <Table.HeadCell className="min-w-[210px]">
            <p className="text-body-5 font-medium text-metal-400">Nombre</p>
          </Table.HeadCell>
          <Table.HeadCell className="min-w-[140px]">Total</Table.HeadCell>
          <Table.HeadCell className="min-w-[151px]">Fecha</Table.HeadCell>
          <Table.HeadCell className="min-w-[151px]">Nro cuenta</Table.HeadCell>
          <Table.HeadCell className="min-w-[80px]" />
        </Table.Head>
        <Table.Body className="divide-gray-25 divide-y">
          {currentData.map((item, index) => (
            <Table.Row style={{ display: (item.activate === false ? "none" : "") }} className="bg-white" key={index}>
              <Table.Cell>
                <div className="flex items-center gap-3">
                  <Avatar shape="circle" img={excelIcon} size="md" />
                  <p className="-mb-0.5 text-body-4 font-medium text-metal-600">{`${item.namedes}.xlsx`}</p>
                </div>
              </Table.Cell>
              <Table.Cell>
                <p className="text-body-5 font-medium text-metal-500">
                  {formatCurrencydes(item.Monto)}
                </p>
              </Table.Cell>
              <Table.Cell>
                <p className="text-body-5 font-medium text-metal-500">
                  {formatDateTime(item.FechaD)}
                </p>
              </Table.Cell>
              <Table.Cell>
                <p className="text-body-5 font-medium text-metal-500">
                  {item.CuentaBancaria}
                </p>
              </Table.Cell>
              <Table.Cell>
                <Button
                  disabled={isLoadingfile}
                  color="secondary"
                  variant="outline"
                  size="sm"
                  shape="circle"
                  onClick={getdesinfo(item.idcliente, item.namedes)}
                >
                  <DownloadSimple size={15} />
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div className="pagination-controls flex justify-end items-center px-6 py-2">
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          className="pagination-button"
          style={{ background: "transparent", border: "none" }}
        >
          <ArrowBackIosIcon style={{ fontSize: "20px", color: "gray" }} />
        </button>
        <span style={{ margin: "0 10px", color: "gray", fontSize: "14px" }}>
          Página {currentPage} de {totalPages}
        </span>
        <button
          onClick={nextPage}
          disabled={currentPage === totalPages}
          className="pagination-button"
          style={{ background: "transparent", border: "none" }}
        >
          <ArrowForwardIosIcon style={{ fontSize: "20px", color: "gray" }} />
        </button>
      </div>
    </div>
  );
};

export default Desembolsar;
