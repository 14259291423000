import React, { useEffect, useState } from "react";
import "../assets/css/Login.css";
import COVER_IMAGE from "../assets/img/COVER_IMG.png";
import IpaidLogo from "../assets/img/Ipaid.png";
import Loading from "../component/Loading";
import { Input, Label, Icon, Button } from "keep-react";
import { Envelope, EyeSlash, Eye, Lock } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import Footer from "../component/Footer";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  let nit;
  //estados
  const [showPassword, setShowPassword] = React.useState(false);
  const [form, setForm] = React.useState({ correo: "", password: "" });
  const [ErrorLogin, setErrorLogin] = React.useState(false);
  const [camposVacios, setCamposVacios] = React.useState(false);

  // funciones
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
    var input = document.getElementById("txtPassword");
    if (input) {
      if (input.type === "password") {
        input.type = "text";
      } else {
        input.type = "password";
      }
    }
  };

  async function getConfigEmpresa(nit) {
    try {
      //   console.log("nit:", nit);
      const response = await fetch(`${apiUrl}/getconfigempresa/`, {
        method: "GET", // Cambiado a GET
        headers: {
          Authorization: "Bearer " + nit,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }

      const data = await response.json();

      return data; // Devuelve los datos para ser usados en el componente
    } catch (error) {
      console.error("Error:", error);
      throw error; // Lanza el error para manejarlo en el componente
    }
  }

  async function getUsers(correo, password) {
    try {
      //   console.log("apiUrl:", apiUrl);
      const response = await fetch(
        `${apiUrl}/getUsuarios?email=${correo}&pss=${password}`,
        {
          method: "GET", // Cambiado a POST
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Error en la solicitud");
      }

      const data = await response.json();
      nit = await data.nit;
      return data; // Devuelve los datos para ser usados en el componente
    } catch (error) {
      console.error("Error:", error);
      throw error; // Lanza el error para manejarlo en el componente
    }
  }
  const validateForm = (e) => {
    if (form.correo === "" || form.password === "") {
      //   alert("Los campos no pueden estar vacios");
      setCamposVacios(true);
      return false;
    }
    return true;
  };

  const handlechage = (e) => {
    // console.log(e.target.name, e.target.value);
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const singIn = (e) => {
    const res = validateForm();
    if (res) {
      setLoading(true); // Inicia el indicador de carga
      getUsers(form.correo, form.password)
        .then((data) => {
          const nit = data.nit; // Obtiene 'nit' de la respuesta de 'getUsers'
          localStorage.setItem("Empresa", nit);
          return getConfigEmpresa(nit); // Encadena la promesa para 'getConfigEmpresa'
        })
        .then((dataConfigEmpresa) => {
          localStorage.setItem("config", JSON.stringify(dataConfigEmpresa));
          localStorage.setItem("sesion", true);
          navigate("/Layout");
        })
        .catch((error) => {
          console.log(error);
          setErrorLogin(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setCamposVacios(false);
      setErrorLogin(false);
    }, 6000);
  }, [camposVacios || ErrorLogin]);


  return (
    <>
      {loading && <Loading />}
      <div className="min-h-screen flex flex-col overflow-hidden">
        <div className="flex-grow flex items-center justify-center bg-[#f5f5f5] overflow-hidden">
          <div className="flex w-full max-w-screen-lg h-[80vh] bg-white shadow-xl rounded-lg">
            {/* Imagen de la izquierda */}
            <div className="hidden lg:block w-1/2 h-full">
              <div className="aspect-w-16 aspect-h-9">
                <img
                  src={COVER_IMAGE}
                  alt="Descripción de la imagen"
                  className="w-full h-full object-cover rounded-l-lg"
                />
              </div>
            </div>

            {/* Formulario */}
            <div className="w-full lg:w-1/2 p-10 flex flex-col justify-center">
              <img
                src={IpaidLogo}
                alt="logo"
                className="w-[60px] mx-auto lg:hidden mb-4"
              />
              <h1 className="text-center text-3xl font-extrabold mb-2">
                Inicia sesión
              </h1>
              <h3 className="text-center text-lg font-semibold mb-6">
                Bienvenidos!
              </h3>
              <fieldset className="space-y-6">
                <div className="space-y-2">
                  <Label htmlFor="correo" value="Correo" />
                  <div className="relative">
                    <Input
                      onChange={handlechage}
                      name="correo"
                      placeholder="example@gmail.com"
                      className="ps-11"
                    />
                    <Icon>
                      <Envelope size={20} color="#5E718D" />
                    </Icon>
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="password" value="Password" />
                  <div className="relative">
                    <Input
                      onChange={handlechage}
                      name="password"
                      placeholder="**********"
                      type={showPassword ? "text" : "password"}
                      className="ps-11"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          singIn();
                        }
                      }}
                    />
                    <Icon>
                      <Lock size={20} color="#5E718D" />
                    </Icon>
                    <button
                      onClick={handleShowPassword}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    >
                      {showPassword ? (
                        <Eye size={20} color="#5E718D" />
                      ) : (
                        <EyeSlash size={20} color="#5E718D" />
                      )}
                    </button>
                  </div>
                </div>

                {camposVacios && (
                  <div className="text-yellow-700 bg-yellow-100 p-3 rounded-md text-sm">
                    Los campos no pueden estar vacíos.
                  </div>
                )}

                {ErrorLogin && (
                  <div className="text-red-700 bg-red-100 p-3 rounded-md text-sm">
                    Usuario o contraseña incorrectos.
                  </div>
                )}

                <button
                  onClick={singIn}
                  className="w-full bg-black text-white rounded-md p-3 mt-4 hover:bg-gray-900"
                >
                  Login
                </button>
              </fieldset>
            </div>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default Login;
