import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  return (
    <footer className="bg-gray-200 text-black text-center p-4">
      <div className="container mx-auto">
        <div className="flex justify-center items-center space-x-4">
          <div className="flex items-center">
            <i className="fa-solid fa-phone text-lg"></i>
            <p className="ml-2 text-gray-800 font-semibold">3246595153</p>
          </div>
          <div className="flex items-center">
            <i className="fa-solid fa-envelope text-lg"></i>
            <p className="ml-2 font-semibold">info@ipaid.com.co</p>
          </div>
        </div>
        <p className="text-gray-800 text-sm mt-4">
          © {new Date().getFullYear()} Ipaid. Todos los derechos reservados.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
