import React, { useEffect, useState, useRef } from "react";
import Loading from "../component/Loading";
import { formatCurrency, formatCurrencydes } from "../data/utilidades";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";

import { BsBoxSeam } from "react-icons/bs";
import { FiBarChart } from "react-icons/fi";
import { MdOutlineSupervisorAccount } from "react-icons/md";

import { HiOutlineRefresh } from "react-icons/hi";
import Img_bancolombia from "../assets/img/bancolombia.png";
import Img_pse from "../assets/img/pse-logo.png";
import TCredito from "../assets/img/Tarjetas1.png";
import {
  Avatar,
  Badge,
  Table
} from 'keep-react';

const Resumen = ({ changeoption }) => {
  const idcliente = localStorage.getItem("Empresa");
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const [amoutMoth, setAmoutMoth] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [otroIcons, setOtroIcons] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const dataFetched = useRef(false);
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  useEffect(() => {
    //console.log(1);

    //const sesion = localStorage.getItem("sesion");
    // const fetchData = async () => {
    fetchData();
  }, []);
  const init = () => {
    fetch(
      `${REACT_APP_API_URL}/cliente/getclientesT`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setdata(data);
        // console.log("data 2", data.data);
      })
      .catch((error) => {
        setError(error.message);
      });
  };
  const fetchData = () => {
    if (!dataFetched.current) {
      dataFetched.current = true;
      setLoading(true);
      fetch(`${REACT_APP_API_URL}/getSumMoth?idcliente=${idcliente}`,
        {
          method: "GET"
        }
      ).then((responsemouth) => {
        if (!responsemouth.ok) {
          throw new Error("Network response was not ok");
        }
        return responsemouth.json();
      }).then((datamouth) => {
        if (datamouth) {
          setAmoutMoth(datamouth);
          fetch(`${REACT_APP_API_URL}/getSumByWeek?idcliente=${idcliente}`,
            {
              method: "GET"
            }
          ).then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          }).then((data) => {
            if (data) {
              setPaymentData(data);
            }
            if (idcliente == "901662465")
              init();
          })
            .catch((error) => {
              console.log(error.message);
            })
        }
      })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (("Bancolombia" in amoutMoth)) { //&& paymentData.length>0) {
      const newTransactionsData = [
        {
          icon: img("bancolombia"),
          name: "Bancolombia",
          detail: "Transferido",
          amount: "+" + formatCurrency(amoutMoth.Bancolombia.thisMonth),
        },
        {
          icon: img("pse"),
          name: "PSE",
          detail: "Transferido",
          amount: "+" + formatCurrency(amoutMoth.PSE.thisMonth),
        },
        {
          icon: img("TCredito"),
          name: "T Credito",
          detail: "Transferido",
          amount: "+" + formatCurrency(amoutMoth.CARD.thisMonth),
        }
      ];
      setTransactions(newTransactionsData);
    }
  }, [amoutMoth]); // Dependencia: amoutMoth

  const earningData = [
    {
      icon: <MdOutlineSupervisorAccount />,
      amount: "399,354",
      percentage: "-4%",
      title: "Customers",
      iconColor: "#03C9D7",
      iconBg: "#E5FAFB",
      pcColor: "red-600",
    },
    {
      icon: <BsBoxSeam />,
      amount: "4,396",
      percentage: "+23%",
      title: "Products",
      iconColor: "rgb(255, 244, 229)",
      iconBg: "rgb(254, 201, 15)",
      pcColor: "green-600",
    },
    {
      icon: <FiBarChart />,
      amount: "423,39",
      percentage: "+38%",
      title: "Sales",
      iconColor: "rgb(228, 106, 118)",
      iconBg: "rgb(255, 244, 229)",

      pcColor: "green-600",
    },
    {
      icon: <HiOutlineRefresh />,
      amount: "39,354",
      percentage: "-12%",
      title: "Refunds",
      iconColor: "rgb(0, 194, 146)",
      iconBg: "rgb(235, 250, 242)",
      pcColor: "red-600",
    },
  ];

  const img = (name) => {
    switch (name) {
      case "bancolombia":
        return (
          <img className="w-6 h-6 mt-3 mb-2" src={Img_bancolombia} alt="bancolombia" />
        );
      case "pse":
        return <img className="w-6 h-6 mt-3 mb-2" src={Img_pse} alt="pse" />;
      case "TCredito":
        return <img className="w-6" src={TCredito} alt="pse" />;
      default:
        break;
    }
  };

  // const paymentData = [
  //   { name: "1", Bancolombia: 400, PSE: 240 },
  //   { name: "2", Bancolombia: 300, PSE: 139 },
  //   { name: "3", Bancolombia: 200, PSE: 980 },
  //   { name: "4", Bancolombia: 278, PSE: 390 },
  //   // ... más datos
  // ];

  function generateMonthOptions() {
    const formatter = new Intl.DateTimeFormat("es", {
      month: "short",
      year: "numeric",
    });

    const currentMonth = new Date();
    const previousMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 1,
      1
    );
    const lastTwoMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 2,
      1
    );

    const formattedLastTwotMonth = formatter.format(lastTwoMonth);
    const formattedCurrentMonth = formatter.format(currentMonth);
    const formattedPreviousMonth = formatter.format(previousMonth);

    return [
      formattedLastTwotMonth,
      formattedPreviousMonth,
      formattedCurrentMonth,
    ];
  }

  const transactionElements = transactions.map((transaction, index) => (
    <div key={index} className="flex justify-between items-center p-2 border-b">
      <div className="flex items-center">
        {transaction.icon}
        <div className="ml-4">
          <p className="font-semibold">{transaction.name}</p>
          <p className="text-sm text-gray-400">{transaction.detail}</p>
        </div>
      </div>
      <span className="font-semibold text-green-500">{transaction.amount}</span>
    </div>
  ));
  const formatter = new Intl.DateTimeFormat("es", {
    month: "short",
    year: "numeric",
  });
  const [selectedMonth, setSelectedMonth] = useState(
    formatter.format(new Date())
  );
  const monthOptions = generateMonthOptions();

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    switch (e.target.value) {
      case monthOptions[0]:
        setTransactions([
          {
            icon: img("bancolombia"),
            name: "Bancolombia",
            detail: "Transferido",
            amount: "+" + formatCurrency(amoutMoth.Bancolombia.lastTwoMonth),
          },
          {
            icon: img("pse"),
            name: "PSE",
            detail: "Transferido",
            amount: "+" + formatCurrency(amoutMoth.PSE.lastTwoMonth),
          },
          {
            icon: img("TCredito"),
            name: "T Credito",
            detail: "Transferido",
            amount: "+" + formatCurrency(amoutMoth.CARD.lastTwoMonth),
          }
        ]);
        break;
      case monthOptions[1]:
        setTransactions([
          {
            icon: img("bancolombia"),
            name: "Bancolombia",
            detail: "Transferido",
            amount: "+" + formatCurrency(amoutMoth.Bancolombia.lastMonth),
          },
          {
            icon: img("pse"),
            name: "PSE",
            detail: "Transferido",
            amount: "+" + formatCurrency(amoutMoth.PSE.lastMonth),
          },
          {
            icon: img("TCredito"),
            name: "T Credito",
            detail: "Transferido",
            amount: "+" + formatCurrency(amoutMoth.CARD.lastMonth),
          }
        ]);
        break;
      case monthOptions[2]:
        setTransactions([
          {
            icon: img("bancolombia"),
            name: "Bancolombia",
            detail: "Transferido",
            amount: "+" + formatCurrency(amoutMoth.Bancolombia.thisMonth),
          },
          {
            icon: img("pse"),
            name: "PSE",
            detail: "Transferido",
            amount: "+" + formatCurrency(amoutMoth.PSE.thisMonth),
          },
          {
            icon: img("TCredito"),
            name: "T Credito",
            detail: "Transferido",
            amount: "+" + formatCurrency(amoutMoth.CARD.thisMonth),
          }
        ]);
        break;
      default:
        break;
    }
  };
  const update = () => {
    dataFetched.current = false;
    fetchData();
  }
  const transactionTypes = [
    { key: "BANCOLOMBIA_TRANSFER", icon: "bancolombia" },
    { key: "PSE", icon: "pse" },
    { key: "CARD", icon: "TCredito" }
  ];
  return (
    <div className="flex flex-col items-center justify-center w-full h-full overflow-x-hidden">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-wrap justify-center gap-4 items-center w-full px-4">
            {otroIcons ? (
              <>
                {earningData.map((item) => (
                  <div
                    key={item.title}
                    className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 p-4 pt-9 rounded-2xl "
                  >
                    <button
                      type="button"
                      style={{
                        color: item.iconColor,
                        backgroundColor: item.iconBg,
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                    >
                      {item.icon}
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold">
                        {item.amount}
                      </span>
                      <span className={`text-sm text-${item.pcColor} ml-2`}>
                        {item.percentage}
                      </span>
                    </p>
                    <p className="text-sm text-gray-400  mt-1">{item.title}</p>
                  </div>
                ))}
              </>
            ) : null}
          </div>
          <div className="flex flex-col w-full px-4 my-2 space-y-4 md:flex-row md:space-y-0 md:space-x-4">
            <div className="bg-white shadow-lg rounded-lg p-6 w-full md:w-1/2">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-gray-700">
                  Resumen de Transacciones
                </h2>
                <button
                  type="button"
                  onClick={update}
                  className="bg-transparent flex justify-center items-center"
                  style={{
                    width: "40px",
                    height: "40px",
                    transition: "transform 0.5s ease-in-out",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = "rotate(360deg)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = "rotate(0deg)")
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </button>
                <div className="relative">
                  <select
                    value={selectedMonth}
                    onChange={(e) => handleMonthChange(e)}
                    className="block appearance-none w-full bg-transparent border-0 border-b-2 border-gray-300 p-2 leading-tight focus:outline-none focus:ring-0 focus:border-pink-500"
                    style={{ paddingRight: "1.5rem", width: "105px" }}
                  >
                    {monthOptions.map((month, index) => (
                      <option key={index} value={month}>
                        {month.charAt(0).toUpperCase() + month.slice(1)}{" "}
                        {/* Capitaliza la primera letra */}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    {/* Ícono de flecha */}
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M5.5 7l4.5 4.5L14.5 7z" />
                    </svg>
                  </div>
                </div>
              </div>
              {transactionElements}
              <div className="text-right mt-4">
                <a style={{ cursor: 'pointer' }}
                  //href="#"
                  // onClick={() => navigate("/Transacciones")}
                  onClick={() => changeoption("Transacciones")}
                  className="flex items-center justify-end text-indigo-600 hover:text-indigo-700 font-medium text-sm transition duration-300 ease-in-out"
                >
                  <span className="mr-2">Ver todas las transacciones</span>
                  <i className="fas fa-arrow-right"></i>{" "}
                  {/* Si usas Font Awesome */}
                  {/* O usar un emoji o carácter Unicode: <span>➔</span> */}
                </a>
              </div>
            </div>
            {/* otro diagrama */}
            <div className="w-full md:w-1/2 bg-white shadow-lg rounded-lg p-6">
              <h2 className="text-xl font-bold text-gray-700 mb-5">
                Métodos de Pago Semanal
              </h2>
              <ResponsiveContainer width="100%" height={150}>
                <LineChart
                  width={500}
                  height={300}
                  data={paymentData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="Bancolombia"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                  <Line type="monotone" dataKey="PSE" stroke="#82ca9d" />
                  <Line type="monotone" dataKey="CARD" stroke="#03C9D7" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          {idcliente == "901662465" && (<div className="w-full md:w-[97%] bg-white shadow-lg rounded-lg p-6">
            <Table className="w-full table-auto">
              <Table.Caption>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-5">
                    <h2 className="text-xl font-bold text-gray-700">Transacciones Mes actual</h2>
                  </div>
                </div>
              </Table.Caption>
              <Table.Head>
                <Table.HeadCell className="w-[140px]">
                  Nombre
                </Table.HeadCell>
                {/* <Table.HeadCell className="w-[100px]">Telefono</Table.HeadCell> */}
                <Table.HeadCell className="w-[130px]">Email</Table.HeadCell>
                <Table.HeadCell className="w-[130px]">Cantidad Transacciones</Table.HeadCell>
                <Table.HeadCell className="w-[140px]">Tipo Transacciones</Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-gray-25 divide-y">
                {data.map((item) => (
                  <Table.Row key={item.idcliente}>
                    <Table.Cell>
                      <div className="flex items-center gap-2">
                        <Avatar img={item.imagen}>
                        </Avatar>
                        <div>
                          <p className="text-body-4 font-medium">{item.nombre}</p>
                        </div>
                      </div>
                    </Table.Cell>
                    {/* <Table.Cell>
                      <p className="text-body-4 font-medium">{item.Telefono}</p>
                    </Table.Cell> */}
                    <Table.Cell><p className="text-body-4 font-medium">{item.correo}</p></Table.Cell>
                    <Table.Cell>
                      <div className="flex items-center gap-1">
                        <Badge showIcon className="text-body-4" color="success">A:{item.transactionstatus.APPROVED}</Badge>
                        <Badge className="text-body-4" color="warning">P:{item.transactionstatus.PENDING}</Badge>
                        <Badge className="text-body-4" color="error">D:{item.transactionstatus.DECLINED}</Badge>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="grid grid-cols-3 gap-2 w-[300px]">
                        {transactionTypes.map(({ key, icon }) => (
                          <div key={key} className="flex flex-col items-center p-2 border rounded h-[80px]">
                            {img(icon)}
                            <span className="font-semibold text-green-500 mt-1">
                              +{formatCurrencydes(item.transactiontype[key])}
                            </span>
                            {/* <span className="font-semibold text-green-500 truncate" style={{ maxWidth: '100px' }}>
                              +{formatCurrencydes(item.transactiontype[key])}
                            </span> */}
                          </div>
                        ))}
                      </div>
                    </Table.Cell>
                    {/* <Table.Cell>
                      <div className="flex items-center">
                        {img("bancolombia")}
                        <div className="ml-1 mr-2">
                          <span className="font-semibold text-green-500">+{formatCurrencydes(item.transactiontype.BANCOLOMBIA_TRANSFER)}</span>
                        </div>
                        {img("pse")}
                        <div className="ml-1 mr-2">
                          <span className="font-semibold text-green-500">+{formatCurrencydes(item.transactiontype.PSE)}</span>
                        </div>
                        {img("TCredito")}
                        <div className="ml-1 mr-1">
                          <span className="font-semibold text-green-500">+{formatCurrencydes(item.transactiontype.CARD)}</span>
                        </div>
                      </div>
                    </Table.Cell> */}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>)}
        </>
      )}
    </div>
  );
};

export default Resumen;
